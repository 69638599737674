import { Component, ElementRef, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../auth.service';
import intlTelInput from 'intl-tel-input';
import { ToastService } from '../../../core/services/toast.service';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { VerifyOtpFormComponent } from '../verify-otp-form/verify-otp-form.component';

@Component({
  selector: 'app-registration-form',
  templateUrl: './registration-form.component.html',
  styleUrl: './registration-form.component.scss'
})
export class RegistrationFormComponent {
  @ViewChild('phoneNumber', { static: true }) phoneNumberInput!: ElementRef;
  accountForm!: FormGroup;
  activeRole: string = 'Franchisee';
  private iti: any;
  isProcessing: boolean = false;
  showPassword: boolean = false;
  referrer!: string | null;
  qrRef: boolean = false;
  mode: string = 'registration';
  step: number;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private auth: AuthService,
    private toast: ToastService,
    private modal: NgbModal,
    public activeModal: NgbActiveModal,
  ) {
    this.step = 1;
  }

  ngOnInit(): void {
    const input = this.phoneNumberInput?.nativeElement;
    this.iti = intlTelInput(input, {
      initialCountry: 'id',
      separateDialCode: true,
      formatAsYouType: true,
      showFlags: true
    });

    this.accountForm = this.fb.group({
      activeRole: [this.activeRole, Validators.required],
      name: ['', [Validators.required]],
      phoneNumber: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(8)]],
      isFranchisee: [true, [Validators.required]],
      referrer: [this.referrer],
    });
  }

  togglePasswordVisibility() {
    this.showPassword = !this.showPassword;
  }

  isControlInvalid(controlName: string, errorType?: string): boolean | undefined {
    const control = this.accountForm?.get(controlName);

    if (!control) {
      return undefined;
    }

    if (errorType) {
      return control.hasError(errorType) && (control.touched || control.dirty);
    } else {
      return control.invalid && (control.touched || control.dirty);
    }
  }

  onSubmit(event: Event) {
    event.preventDefault();
    const countryCode = this.iti.getSelectedCountryData().dialCode;
    const phoneNumber = this.accountForm.get('phoneNumber')?.value.replace(/^[0+]+/, '');
    if (this.accountForm.invalid) {
      this.accountForm.markAllAsTouched();
      return;
    } else {
      this.isProcessing = true;
      const accountDetail = {
        ...this.accountForm.value,
        phoneNumber: countryCode + phoneNumber
      };

      this.auth.register(accountDetail).subscribe({
        next: (response: Response) => {
          this.isProcessing = false;
          this.toast.show({textOrHtml: 'Successfully registered!', classname: 'bg-success text-white', delay: 1500});
          this.openVerifyOtpForm(accountDetail);
        },
        error: (error) => {
          this.isProcessing = false;
          if (error.error.message === 'You are already registered, please proceed to login') {
            this.toast.show({textOrHtml: `You are already registered, please proceed to <a href="/login">login</a>`, classname: 'bg-danger text-white', delay: 1500});
          } else {
            this.toast.show({textOrHtml: error.error.message, classname: 'bg-danger text-white', delay: 1500});
          }
        }
      });
    }
  }

  openVerifyOtpForm(accountDetail: any) {
    const modalRef = this.modal.open(VerifyOtpFormComponent, { centered: true, keyboard: false, backdrop: 'static', size: 'lg' });
    modalRef.componentInstance.accountForm = accountDetail;
    this.activeModal.close();
  }

  getInvalidControls(): string[] {
    const invalidControls: string[] = [];
    Object.keys(this.accountForm.controls).forEach(controlName => {
      const control = this.accountForm.get(controlName);
      if (control && control.invalid) {
        invalidControls.push(controlName);
      }
    });
    return invalidControls;
  }

}
