import { Component, ElementRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../../auth.service';
import intlTelInput from 'intl-tel-input';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastService } from '../../../core/services/toast.service';
import { CartService } from '../../../core/services/cart.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-login-form',
  templateUrl: './login-form.component.html',
  styleUrl: './login-form.component.scss'
})
export class LoginFormComponent {
  @ViewChild('phoneNumber', { static: true }) phoneNumberInput!: ElementRef;
  phoneNumberInstance: any;
  loginForm!: FormGroup;
  private iti: any;
  returnUrl!: string;
  isAuthenticated: boolean | null = null;
  mode: string = 'login';

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private cart: CartService,
    private route: ActivatedRoute,
    private toast: ToastService,
    private auth: AuthService,
    public activeModal: NgbActiveModal,
  ) {}

  ngOnInit() {
    // this.returnUrl = decodeURIComponent(this.route.snapshot.queryParamMap.get('returnUrl') || '/home');
    this.returnUrl = this.router.url;
    const input = this.phoneNumberInput.nativeElement;
    this.iti = intlTelInput(input, {
      initialCountry: 'id',
      separateDialCode: true,
      formatAsYouType: true,
      showFlags: true
    });

    this.loginForm = this.fb.group({
      phoneNumber: ['', [Validators.required, Validators.minLength(8)]],
      password: ['', [Validators.required, Validators.minLength(8)]]
    })
  }

  onSubmit() {
    const countryCode = this.iti.getSelectedCountryData().dialCode;
    const phoneNumber = this.loginForm.get('phoneNumber')?.value.replace(/^[0+]+/, '');

    if (this.loginForm.valid) {
      const fullPhoneNumber = countryCode + phoneNumber;
      this.auth.login(fullPhoneNumber, this.loginForm.value.password).subscribe({
        next: (response: any) => {
          if (response.data.activeRole === 'Franchisee') {
            console.log(response.data);
            localStorage.setItem('userId', response.data.id);
            this.cart.initializeCartData();
            this.router.navigateByUrl(this.returnUrl);
          } else if (response.data.activeRole === 'Franchisor') {
            this.router.navigate(['/dashboard']);
          } else if (response.data.activeRole === 'Management') {
            this.router.navigate(['/admin/dashboard']);
          } else if (response.data.activeRole === 'Sales') {
            this.router.navigate(['/sales']);
          }
          this.activeModal.close();
          this.toast.show({textOrHtml: 'Successfully logged in!', classname: 'bg-primary text-light', delay: 1500});
        },
        error: (error: any) => {
          console.error('Login error:', error.error.message);
          this.toast.show({textOrHtml: error.error.message, classname: 'bg-danger text-light', delay: 1500});
        }
      });
    }
  }

}
